import React from 'react';
import styles from '../Styles/AppInfo.module.css';

function AppInfo() {
    return (
        <div className={styles.info}>
            <h1>Discover Your Place – Where Communities Connect!</h1>
            <p>Transform viewer metrics into milestones of 
            community achievement. Community Members can access a wide array of metadata, from detailed 
            viewer engagement, subscriber stats and ranks all tailored to enhance the community experience. Powered by ChatGPT  
            AI, SyncBot offers unique engagement tools as well as deep analystics. 
            It's here that data doesn't just inform; it connects and enriches each community.</p>
        </div>
    );
}

export default AppInfo;