import './App.css';
import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
// import StripeWrapper from './Components/StripeWrapper.js';
import HomePage from './Pages/Home.js';
import TopNavbar from './components/TopNavBar.js';
// import FeaturesPage from './Pages/Features.js';
import LogIn from './Pages/Login.js';
// import ContactUsForm from './Pages/ContactUs.js';
// import Communities from './Pages/Communities.js';
// import PricingPage from './Pages/Pricing.js';
// import { createTheme, colors, ThemeProvider, Box } from '@mui/material';
// import { blue, grey } from '@mui/material/colors';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          {/* <Route path="checkout" element={<StripeWrapper />} /> */}
          {/* <Route path="features" element={<FeaturesPage />} />
          <Route path="pricing" element={<PricingPage />} /> */}
          <Route path="login" element={<LogIn />} />
          {/* <Route path="contact" element={<ContactUsForm />} />
          <Route path="communities" element={<Communities />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}

function MainLayout() {
  return (
    <div>
      <TopNavbar />
      <Outlet />
    </div>
  );
}


export default App;
