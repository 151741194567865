import React, { useEffect } from 'react';
import Timeline from '../components/Timeline';
import Footer from '../components/Footer';
import AppInfo from '../components/AppInfo';


function HomePage() {
    const userId = '1234567890'

    return (
        <div className='content'>
            <AppInfo />
            <Timeline />

            {/* <Footer /> */}
        </div>
    );
}

export default HomePage;