// src/client/src/components/Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser } from '@fortawesome/free-solid-svg-icons';
import styles from '../Styles/TopNavbar.module.css';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import axios from 'axios';

const dropDownMenu = [
    {
        title: 'Dashboard',
        icon: faUser,
        link: '/dashboard',
    },
    {
        title: 'Logout',
        icon: faCog,
        link: '/logout',
    },
];


async function sendTokenToBot(token, refreshToken, userId) {
    try {
        const response = await axios.post('http://localhost:48042/api/auth', {
            userId: userId,
            token: token,
            refreshToken: refreshToken,
        });
        return response.data;
    } catch (error) {
        console.log(`Error sending token to bot: ${error}`);
        return null;
    }
}


function TopNavbar() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        fetch('https://api.syncbot.gg/auth/user/logged-in', {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                if (data.isLoggedIn) {
                    console.log('User is logged in:', data.data);
                    setLoggedIn(true);
                    setProfileImageUrl(data.data.profileImageUrl);
                    setDisplayName(data.data.displayName);
                    if (data.data.token && data.data.refreshToken) {
                        sendTokenToBot(data.data.token, data.data.refreshToken, data.data.twitchUserId)
                    }
                }
                setIsLoaded(true);
            })
            .catch(error => {
                console.error('Error fetching auth data:', error);
            });
        // Add a click event listener to close the dropdown when clicking outside
        document.addEventListener('click', handleDocumentClick);

        // Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const toggleDropdown = () => {
        console.log('toggleDropdown');
        setShowDropdown(!showDropdown); // Toggle the dropdown menu visibility
    };

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    const handleDocumentClick = (event) => {
        // Check if the click occurred outside the dropdown
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeDropdown();
        }
    };

    const handleLoginClick = () => {
        window.location.href = '/login';
    }

    return (
        <nav className={styles.navbar}>
            <Box className={styles.navBrand}>
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" />
                <p>SyncBot</p>
            </Box>
            <Box className={styles.navLinks}>
                <a href="/">Home</a>
                {/* <a href="/features">Features</a>
                <a href="/pricing">Pricing</a>
                <a href="/communities">Communities</a> */}
            </Box>
            {isLoaded && loggedIn ? (
                <Box className={styles.userProfile} ref={dropdownRef}>
                    <img
                        src={profileImageUrl || faUser}
                        alt="User profile"
                        onClick={toggleDropdown}
                    />
                    {showDropdown && (
                        <Box className={styles.dropdownMenu}>
                            <span className='navbar-dropdown-menu-user'>{displayName}</span>
                            {dropDownMenu.map((item, index) => (
                                <a key={index} href={item.link}>
                                    <FontAwesomeIcon icon={item.icon} />
                                    {item.title}
                                </a>
                            ))}
                        </Box>
                    )}
                </Box>
            ) : (
                <Box sx={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }
                }>
                    <Box className={styles.navLogin} onClick={handleLoginClick}>Login</Box>
                </Box>
            )}
        </nav>
    );
}

export default TopNavbar;
