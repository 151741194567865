import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import styles from '../Styles/Login.module.css';

const logIns = [
    {
        title: 'Login with Twitch',
        icon: faTwitch,
        link: 'https://api.syncbot.gg/auth/twitch/streamer',
        service: 'twitch',
        bgColor: '#6441A4',
    },
];

function LogIn() {
    const [isLoaded, setIsLoaded] = useState(false);
    const logInRef = useRef(null);

    useEffect(() => {
        // Check if there's a token in the URL
        console.log('Current URL:', window.location.href);
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        
        if (token) {
            console.log('Token:', token);
            // Store the token in localStorage
            localStorage.setItem('jwtToken', token);
            // Redirect to the desired page, e.g., dashboard
            window.location.href = '/';
        } else {
            console.log('No token found');
        }
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.button} ref={logInRef}>
                {logIns.map((logIn, index) => (
                    <a
                        key={index}
                        href={logIn.link}
                        className="login-button-link"
                        style={{ backgroundColor: logIn.bgColor }}
                    >
                        <FontAwesomeIcon icon={logIn.icon} className={styles.icon} />
                        <span className={styles.text}>{logIn.title}</span>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default LogIn;