import React from 'react';
import styles from '../Styles/Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord, faTiktok } from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.socialLinks}>
                <a href="https://twitter.com/"><FontAwesomeIcon icon={faTwitter} className={styles.icon}/></a>
                <a href="https://discord.com/"><FontAwesomeIcon icon={faDiscord} className={styles.icon}/></a>
                <a href="https://www.tiktok.com/"><FontAwesomeIcon icon={faTiktok} className={styles.icon}/></a>
            </div>
            <div className={styles.legalLinks}>
                <a href="#terms">Terms of Service</a>
                <a href="#privacy">Privacy Policy</a>
            </div>
        </footer>
    );
}

export default Footer;
