import React, { useState, useEffect } from 'react';
import styles from '../Styles/Timeline.module.css';


function TimelineItem({ position, title, children }) {
    const itemClass = position === "Right" ? `${styles.item} ${styles.itemRight}` : `${styles.item} ${styles.itemLeft}`;

    return (
        <div className={itemClass}>
            <div className={styles.dot}></div>
            <div className={styles.timelineContent}>
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    );
}

function Timeline() {
    const handleInterstedButtonClick = () => {
        window.location.href = '/contact';
    }

    return (
        <div className={styles.wrapper}>
            <h1>Sync Bot Timeline</h1>
            <div className={styles.timelineContainer}>
                <div className={styles.timelineLine}></div>
                <TimelineItem position="Right" title="September 2024">
                    <div className={styles.timelineInnerWrapper}>
                        <h4>Private Beta Launch</h4>
                        <p>
                            We're thrilled to announce that we've embarked on the first step of an incredible journey
                            with Sync Bot - the private beta phase. This is a crucial time for us, where a small
                            group of invited participants are helping us fine-tune our features, squash any sneaky bugs,
                            and ensure that our app delivers the best possible experience.
                        </p>
                    </div>
                    <div className={styles.timelineInnerWrapper}>
                        <h4>Why a private beta?</h4>
                        <p>
                            It's all about quality and care. We want to ensure that every element of our app meets your
                            expectations. This phase allows us to gather detailed feedback and make necessary adjustments
                            in a focused setting.
                        </p>
                    </div>
                </TimelineItem>
                <TimelineItem position="Left" title="October 2024">
                    <div className={styles.timelineInnerWrapper}>
                        <h4>Invite Beta Launch</h4>
                        <p>
                            The next stage in our app's journey is an invite-only beta phase designed
                            to bring Sync Bot closer to perfection. For this phase, we're extending invitations to
                            a larger users base who will have exclusive access to test our latest features and provide us
                            with valuable feedback.
                        </p>
                    </div>
                    <div className={styles.timelineInnerWrapper}>
                        <h4>Why an Invite-Only Beta?</h4>
                        <p>
                            This approach allows us to engage closely with a slightly larger group of users, ensuring that we can incorporate
                            feedback efficiently and enhance the app experience in a controlled, yet dynamic environment.
                        </p>
                    </div>
                </TimelineItem>
                <TimelineItem position="Right" title="November 2024">
                    <div className={styles.timelineInnerWrapper}>
                        <h4>Public Beta!</h4>
                        <p>
                            The journey of Sync Bot development has reached an exciting milestone – the launch of our Public Beta phase, and you're all invited
                            to join us! This is your chance to get hands-on with our app, explore its features, and contribute to its final polish.
                            The Public Beta phase is crucial for stress-testing Sync Bot with a wider audience. It's about pushing the boundaries, identifying any
                            issues under varied real-world conditions, and ensuring that our app is robust, user-friendly, and ready for launch. Your feedback will play a pivotal
                            role in this phase, helping us fine-tune the app to better meet your needs and expectations.
                        </p>
                    </div>
                </TimelineItem>
                <TimelineItem position="Left" title="December 2024">
                    <div className={styles.timelineInnerWrapper}>
                        <h4>Full Release</h4>
                        <p>
                            The full release of Sync Bot is planned for December 2024.
                        </p>
                    </div>
                </TimelineItem>
            </div>
            <div className={styles.qanda}>
                <h2>Questions & Answers</h2>
                <div className={styles.question}>
                    <h3>Will you support Kick?</h3>
                    <p>
                        Yes, we will fully support Kick. Kick is still in the process of developing their API. As soon as it becomes available, we will integrate it into Sync Bot.
                    </p>
                </div>
                <div className={styles.question}>
                    <h3>Will you support YouTube?</h3>
                    <p>
                        Yes, we will support youtube in the future. No ETA yet.
                    </p>
                </div>
                {/* <div className={styles.question}>
                    <h3>How can I join the beta phase?</h3>
                    <p>
                        If you're interested in joining the beta phase, click on the "I'm Interested" button in the respective section above. We'll review your interest and send you an invitation to join the beta phase.
                    </p>
                </div> */}
            </div>
            {/* <button className={styles.interested} onClick={handleInterstedButtonClick}>I'm Interested</button> */}
        </div>
    );
}

export default Timeline;
